import {Fragment} from "react";
import classes from './index.module.scss';
import MainSection, {MainSectionTypes} from "components/main-section";
import RoundProgressBar from "../../components/round-progress-bar";
import {combineClassNames} from "utils/functions";

/* Mock data */
import education from 'mock/education.json';
import experience from 'mock/experience.json';
import personalDetails from 'mock/personal-details.json';
import skills from 'mock/skills';
import languages from 'mock/coding-languages.json';
import tools from 'mock/tools.json';


const RightSide = () => {
    return (
        <div className={classes['right-section-wrapper']}>
            <MainSection title="About Me">
                {personalDetails.about}
            </MainSection>
            <MainSection title="Education" type={MainSectionTypes.Education} data={education} />
            <MainSection title="Experience" type={MainSectionTypes.Experience} data={experience} />
            <MainSection>
                <p className={classes['spoken-languages']}>
                    Native Hebrew and English proficient
                </p>
            </MainSection>
            <div className={classes['footer']}>
                <div className={classes['right-side']}>
                    <span className={classes['title']}>Coding Languages</span>
                    <div className={classes["bars-wrapper"]}>
                        {languages.map((lng, idx) => (
                            <RoundProgressBar
                                key={`coding-language-${lng.title}-${idx}`}
                                value={lng.value}
                                title={lng.title}
                                className={classes['round-bar']}
                            />
                        ))}
                    </div>
                    <p className={combineClassNames(
                        classes['title'],
                        classes['thinner']
                    )}>
                        Other Skills and Languages:
                    </p>
                    <div className={classes['icons-wrapper']}>
                        {skills.map((skill, idx) => (
                            <Fragment key={`skill-${skill.title}-${idx}`}>
                                {/* @ts-ignore */}
                                <ion-icon
                                    name={skill.name}
                                    title={skill.title}
                                    src={skill.src}
                                />
                            </Fragment>
                        ))}
                    </div>
                    <p className={combineClassNames(
                        classes["title"],
                        classes['thinner']
                    )}>Tools:</p>
                    <ul>
                        {tools.map((tool, idx) => (
                            <li key={`tool-${tool}-${idx}`}>{tool}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RightSide;