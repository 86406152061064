import {ReactNode} from "react";
import {combineClassNames} from "../../utils/functions";
import classes from "./index.module.scss";

type RoundProgressBarProps = {
    title?: string,
    value: number,
    className?: string
}

const RoundProgressBar = (props: RoundProgressBarProps) => {
    const { title, value, className } = props;
    return (
        <div className={combineClassNames(
            classes['round-progress-bar-wrapper'],
            className
        )}>
            <div className={classes['round-progress-bar']}>
                <svg>
                    <circle cx={'50%'} cy={'50%'} r={'45%'}/>
                    <circle cx={'50%'} cy={'50%'} r={'45%'} className={combineClassNames(
                        classes['progress'],
                        classes[`value-${value}`]
                    )}/>
                </svg>
                <div className={classes['number']}>
                    <h2>{value}<span className={classes['smaller']}>%</span></h2>
                    <span className={classes['title']}>{title}</span>
                </div>
            </div>
        </div>
    )
};

export default RoundProgressBar;