type ProjectsProps = {
    title?: string
};
const Projects = (props: ProjectsProps) => {
    return (
        <div>
            <span className="experienceTitle">{props.title}</span>
            <div className="experienceDetails">
                <svg
                    className="top"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="currentColor"
                        fill-opacity="1"
                        d="M0,128L120,133.3C240,139,480,149,720,138.7C960,128,1200,96,1320,80L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    ></path>
                </svg>{" "}
                <svg
                    className="bottom"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="currentColor"
                        fill-opacity="1"
                        d="M0,128L120,133.3C240,139,480,149,720,138.7C960,128,1200,96,1320,80L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    ></path>
                </svg>
            </div>
        </div>
    );
};

export default Projects;