import classes from './index.module.scss';
import Contact from "components/contact";
import Language from "components/language";
import {combineClassNames} from "utils/functions";
import Benny from 'assets/photo.png';
import contacts from 'mock/contact.json';
import languages from 'mock/verbal-languages.json'
import personalDetails from 'mock/personal-details.json';
import badges from 'mock/badges.json';


const LeftSide = () => {
    return (
        <div className={classes['left-section-wrapper']}>
            <div className={classes['header-wrapper']}>
                <div className={classes['photo-wrapper']}>
                    <img
                        src={Benny}
                        className={classes["photo"]}
                        alt="Benny Kerio"
                    />
                </div>
                <span className={classes["name"]}>{personalDetails.name}</span>
                <span className={classes["title"]}>{personalDetails.title}</span>
                <span className={combineClassNames(
                    classes["title"],
                    classes['smaller'])
                }>
                    {personalDetails.subtitle}
                </span>
            </div>
            <div className={classes['sections-wrapper']}>
                <hr className={classes["line"]}/>
                <div className={classes['left-side-section']}>
                    <span>Contact Info</span>
                    {contacts.map((cnt, idx) => (
                        <Contact
                            key={`contact-${cnt.type}-${idx}`}
                            type={cnt.type}
                            info={cnt.info}
                            link={cnt.link}
                        />
                    ))}
                </div>
                <hr className={classes["line"]} />
                <div className={classes['left-side-section']}>
                    <span>Languages</span>
                    {languages.map((lng, idx) => (
                        <Language
                            key={`verbal-language-${lng.language}`}
                            language={lng.language}
                            percentage={lng.percentage}
                        />
                    ))}
                </div>
                <hr className={classes["line"]}/>
                <div className={classes['badges-wrapper']}>
                    {badges.map((b, idx) => (
                            <Badge {...b}  key={`badge-${idx}`}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeftSide;

type BadgeProps = {
    src: string,
    alt?: string,
    title?: string,
    link?: string
};

const Badge = (props:BadgeProps) => {
    const {
        link,
        src,
        title,
        alt
    } = props;

    const image = (
        <img
            src={src}
            alt={alt ?? title}
            title={title}
        />
    )
    return (
        <div
            className={classes["badge"]}
        >
            {link ? (
                <a href={link}>
                    {image}
                </a>
            ): image}
        </div>
    )
}