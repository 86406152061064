import classes from './index.module.scss';
import ProgressBar from "components/progress-bar";
import {combineClassNames} from "utils/functions";

type LanguageProps = {
    line?: boolean,
    language?: string,
    percentage?: number,
    isRight?: boolean,
    className?: string
};

const Language = (props: LanguageProps) => {
    const {
        line,
        language,
        percentage,
        isRight,
        className
    } = props;

    return (
        <div className={combineClassNames(
            classes['language-wrapper'],
            line ? classes["line-flex-box"] : "",
            className
        )}>
            <span
                className={combineClassNames(
                    classes["language-name"],
                    line ? '':classes["line-flex"]
                )}
            >
                {language}
            </span>
            <ProgressBar
                className={line ? combineClassNames(
                    classes["language-name"],
                    classes["line-flex"]
                ): ""}
                value={percentage}
                direction={isRight ? 'rtl' : 'ltr'}
            />
        </div>
    );
};

export default Language;