import classes from './index.module.scss';
import {combineClassNames} from "utils/functions";
type ProgressBarProps = {
    className?: string,
    direction?: 'rtl' | 'ltr',
    value?: number,
};

const ProgressBar = (props: ProgressBarProps) => {
    const { direction = 'ltr', value } = props;
    return (
        <div className={combineClassNames(
            classes['progress-bar-wrapper'],
            classes[direction],
        )}>
            <div
                className={classes['actual-progress']}
                style={{
                    width: value + "%",
                }}
            ></div>
        </div>
    );
};

export default ProgressBar;