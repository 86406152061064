import Education from "components/education";

type ExperienceProps = {
    workplace: string,
    location?: string,
    startYear: number,
    endYear?: number,
    title: string,
    fields?: string[]
    specialize?: string
};

const Experience = (props: ExperienceProps)=> {
    const {
        workplace,
        location,
        startYear,
        endYear,
        title,
        fields,
        specialize
    } = props;

    const isTillPresent = !endYear

    const locationString = `${workplace}${location ? `, ${location}`:''}`
    const yearsString = `${startYear}-${isTillPresent ? 'Present':endYear}`

    return (
        <Education
            school={locationString}
            year={yearsString}
            title={title}
            specialize={specialize}
        >
            {fields && (
                <ul>
                    {fields.map((field, idx) => (
                        <li key={`${workplace}-field-${idx}`}>
                            {field}
                        </li>
                    ))}
                </ul>
            )}
        </Education>
    );
};

export default Experience;