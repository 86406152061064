import classes from './index.module.scss';
import LeftSide from "./sections/left-section";
import RightSide from "./sections/right-section";

export default function Home() {
  return (
      <main className={classes['main-wrapper']}>
        <div className={classes['sections-wrapper']}>
          <LeftSide/>
          <RightSide/>
        </div>
      </main>
  );
}
