import classes from './index.module.scss';
interface ContactProps {
    type: string,
    link?: string,
    info: string
}
const Contact = (props: ContactProps) => {
    const { type, info, link } = props;

    const isLink = typeof link !== 'undefined';
    return (
        <div className={classes["contact-wrapper"]}>
            {/* @ts-ignore */}
            <ion-icon className={classes["icon"]} name={type}/>
            <span className={classes["info"]}>
                {isLink ? (
                    <a href={link}>{info}</a>
                ) : (
                    info
                )}
            </span>
        </div>
    );
};

export default Contact;