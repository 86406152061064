import classes from './index.module.scss';
import Projects from "components/projects";
import {ReactNode} from "react";
import Experience from "components/experience";

export enum MainSectionTypes {
    Experience = 'experience',
    Education = 'education',
    Projects = 'projects'
}

type MainSectionProps = {
    title?: string,
    type?: MainSectionTypes,
    children?: ReactNode | ReactNode[]
    data?: object[]
};

const MainSection = (props: MainSectionProps) => {
    const {type, title, children, data } = props;

    switch (type) {
        case MainSectionTypes.Projects:
            return <Projects title={title} />;
        case MainSectionTypes.Education:
        case MainSectionTypes.Experience:
            return (
                <div className={classes["main-section-wrapper"]}>
                    <span className={classes["title"]}>{title}</span>
                    {data?.map((item, idx) => (
                        <Experience
                            key={`${type}-${idx}`}// @ts-ignore
                            workplace={item.workplace ?? item.school}// @ts-ignore
                            specialize={item.specialize}// @ts-ignore
                            location={item.location}// @ts-ignore
                            startYear={item.startYear}// @ts-ignore
                            endYear={item.endYear}// @ts-ignore
                            title={item.title}// @ts-ignore
                            fields={item.fields}
                        />
                    ))}
                </div>
            );
        default:
            return (
                <div className={classes["main-section-wrapper"]}>
                    <span className={classes["title"]}>{title}</span>
                    <p className="text">{children}</p>
                </div>
            )
    }
};

export default MainSection;