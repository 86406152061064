import classes from './index.module.scss';
import {ReactNode} from "react";

type EducationProps = {
    school?: string,
    year?: string,
    title?: string,
    specialize?: string,
    children?: ReactNode | ReactNode[],
}

const Education = (props: EducationProps) => {
    const {
        school,
        year,
        title,
        specialize,
        children
    } = props;

    return (
        <div className={classes['education-wrapper']}>
            <div className={classes['school-wrapper']}>
                <span className={classes["school"]}>{school}</span>
                <span className={classes["year"]}>{year}</span>
            </div>
            <div className={classes['title']}>{title}</div>
            {specialize && (
                <div className={classes['speciality']}>Specialized in {specialize}</div>
            )}
            {children && (
                <span className={classes['speciality']}>{children}</span>
            )}
        </div>
    );
};

export default Education;