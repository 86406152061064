import phpLogo from 'assets/php.svg';
import expressLogo from 'assets/express.svg';
import nextLogo from 'assets/next.svg';
import nestLogo from 'assets/nest.svg';


const skills = [
  {
    "title": "react",
    "name": "logo-react"
  },
  {
    "title": "next",
    "src": nextLogo
  },
  {
    "title": "node",
    "name": "logo-nodejs"
  },
  {
    "title": "express",
    "src": expressLogo
  },
  {
    "title": "nest",
    "src": nestLogo
  },
  {
    "title": "html",
    "name": "logo-html5"
  },
  {
    "title": "css",
    "name": "logo-css3"
  },
  {
    "title": "php",
    "src": phpLogo
  }
];

export default skills